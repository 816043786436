import Footer from "./Footer";
import Header from "./Header";
import "./Page.scss";

function Page(props: any) {
    return(<>
        <Header />
        <main>
            {props.children}
        </main>
        <Footer />
    </>)
}

export default Page;
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { decreaseFullScreenLoading, increaseFullScreenLoading } from "../../../appSlice";
import { Config } from "../../../config";
import { currentToken } from "../../login/loginSlice";
import { activeComposingEmail, closeEmail, currentComposingEmail } from "../emailSlice";

function ComposeMailItem(props: any) {
    let userToken = useAppSelector(currentToken);
    const sendEmailMessage = () => {
        dispatch(increaseFullScreenLoading());
        fetch(Config.apiEndpoint + "/api/emails/send", {
            method: "POST",
            mode: "cors",
            headers: {Authorization: "Bearer " + userToken},
            body: JSON.stringify({
                from: emailFrom,
                to: emailTo,
                subject: emailSubject,
                text: emailBody,
                html: emailBody
            })
        }).then((data) => {
            return data.json();
        }).then((data) => {
            dispatch(closeEmail(props.email.guid));
            dispatch(decreaseFullScreenLoading());
        });
    }
    let composingEmailGuid = useAppSelector(currentComposingEmail);
    let [emailFrom, setEmailFrom] = useState("");
    let [emailTo, setEmailTo] = useState("");
    let [emailSubject, setEmailSubject] = useState("");
    let [emailBody, setEmailBody] = useState("");
    let dispatch = useAppDispatch();
    return(<div className={"newEmail" + (composingEmailGuid === props.email.guid ? " active" : "")} key={props.email.guid}>
        <div className={"emailHeader"} onClick={() => dispatch(activeComposingEmail(props.email.guid))}>
            <h1>{props.emailHeader || "New Email"}</h1>
            <div className={"iconsLeft"}>
                <FontAwesomeIcon icon={ faClose } size={"3x"} onClick={() => {
                    dispatch(closeEmail(props.email.guid));
                }} />
            </div>
            <div className={"iconsRight"}>
                <FontAwesomeIcon icon={ faPaperPlane } size={"3x"} onClick={() => {
                    sendEmailMessage();
                }} />
            </div>
        </div>
        <div className={"emailBody"}>
            <input value={emailFrom} placeholder="From Address" onChange={(e) => setEmailFrom(e.target.value)} autoCapitalize={"off"} />
            <input value={emailTo} placeholder="To Address" onChange={(e) => setEmailTo(e.target.value)} autoCapitalize={"off"} />
            <input value={emailSubject} placeholder="Subject" onChange={(e) => setEmailSubject(e.target.value)} />
            <textarea value={emailBody} onChange={(e) => setEmailBody(e.target.value)}></textarea>
        </div>
    </div>);
}

export default ComposeMailItem;
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./app/store";

export interface AppState {
    fullScreenLoadingEvents: number;
    activeFolder: string | null;
    activeMessage: string | null;
}

const initialState: AppState = {
    fullScreenLoadingEvents: 0,
    activeFolder: 'inbox',
    activeMessage: null
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        increaseFullScreenLoading: (state) => {
            state.fullScreenLoadingEvents++;
        },
        decreaseFullScreenLoading: (state) => {
            state.fullScreenLoadingEvents--;
        }
    }
});

export const { increaseFullScreenLoading, decreaseFullScreenLoading } = appSlice.actions;

export const fsLoadingEvents = (state: RootState) => state.app.fullScreenLoadingEvents;

export const currentFolder = (state: RootState) => state.app.activeFolder;

export const currentMessage = (state: RootState) => state.app.activeMessage;

export default appSlice.reducer;
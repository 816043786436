import { EmailMessageType } from "./FolderContents";
function EmailMessageItem(props: any) {
    let message: EmailMessageType = props.message;
    return(
        <div className={"emailMessageItem"} key={message.key} onClick={props.onClick}>
            <div className={"emailSubject"}>{message.subject}</div>
            <div className={"emailFrom"}>{message.from}</div>
        </div>
    );
}

export default EmailMessageItem;
import "./ComponentLoading.scss";

function ComponentLoading(props: any) {
    if (props.isActive) {
        return(<div className={"componentLoading"}>
            <div className={"loadingElement"}>
            </div>
        </div>)
    } else {
        return(<></>);
    }
}

export default ComponentLoading;
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { decreaseFullScreenLoading, increaseFullScreenLoading } from "../../appSlice";
import { login, userLoggedIn } from "./loginSlice";
import { Config } from "../../config";
import { Navigate } from "react-router-dom";
import "./Login.scss";
function Login() {
    const dispatch = useAppDispatch();
    const doLogin = async() => {
        dispatch(increaseFullScreenLoading());
        setLoginError(<></>);
        await fetch(Config.apiEndpoint + "/auth/login/", {
            method: "POST",
            mode: 'cors',
            headers: { 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
                email: username,
                password: password
            })
        })
        .then((response) => {
            if (response.status === 401) {
                // Wrong password
                throw(new Error("LOGIN_FAILED"));
            } else if (response.status === 200) {
                return response.text()
            } else {
                throw(response);
            }
        })
        .then((token) => {
            dispatch(login(token))
        })
        .catch((error) => {
            if (error.message === "LOGIN_FAILED") {
                // Display error
                setPassword("");
                setLoginError(<span className={"validation"}>Login error. Please double check email and password.</span>)
            }
        });
        dispatch(decreaseFullScreenLoading());
    }

    let [username, setUsername] = useState('');
    let [password, setPassword] = useState('');
    let [loginError, setLoginError] = useState(<></>);

    if (useAppSelector(userLoggedIn)) {
        return (<Navigate to="/inbox" />);
    }
    return(<div id={"loginContent"} className={"content"}>
        <h1>Login</h1>
        <label>
            Please enter your company email address:
            <input type="text" placeholder={"email@companyname.com"} value={username} onChange={(e) => {setUsername(e.target.value)}} onKeyDown={(k) => {if (k.key === "Enter"){doLogin()}}} />
        </label>
        <label>
            Please enter your password:
            <input type="password" placeholder={"Password"} value={password} onChange={(e) => {setPassword(e.target.value)}} onKeyDown={(k) => {if (k.key === "Enter"){doLogin()}}} />
        </label>
        {loginError}
        <button className={"genericBtn"} onClick={doLogin}>Log In</button>
    </div>)
}

export default Login;
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface EmailState {
    activeFolder: string | null;
    activeEmail: string | null;
    emailsWritten: any[] | null;
    activeComposingEmail: string | null;
}

const initialState: EmailState = {
    activeFolder: null,
    activeEmail: null,
    emailsWritten: null,
    activeComposingEmail: null
};

export const emailSlice = createSlice({
    name: 'emails',
    initialState,
    reducers: {
        changeFolder: (state, payload) => {
            state.activeFolder = payload.payload.folder;
            state.activeEmail = null;
        },
        loadEmail: (state, payload) => {
            state.activeEmail = payload.payload;
        },
        composeEmail: (state, payload) => {
            if (state.emailsWritten) {
                state.emailsWritten?.push({guid: payload.payload, to: "", from: "", subject: "", body: ""});
            } else {
                state.emailsWritten = [{guid: payload.payload, to: "", from: "", subject: "", body: ""}]
            }
            state.activeComposingEmail = payload.payload;
        },
        closeEmail: (state, payload) => {
            state.emailsWritten = state.emailsWritten!.filter((email) => {
                return email.guid !== payload.payload;
            });
            state.activeComposingEmail = null;
        },
        activeComposingEmail: (state, payload) => {
            state.activeComposingEmail = payload.payload;
        }
    }
});

export const { changeFolder, loadEmail, composeEmail, closeEmail, activeComposingEmail } = emailSlice.actions;

export const activeFolder = (state: RootState) => state.emails.activeFolder || "inbox";

export const activeEmail = (state: RootState) => state.emails.activeEmail;

export const emailsBeingWritten = (state: RootState) => state.emails.emailsWritten;

export const currentComposingEmail = (state: RootState) => state.emails.activeComposingEmail;

export default emailSlice.reducer;
import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Index from './pages/index/Index';
import Login from './pages/login/Login';
import Inbox from './pages/inbox/Inbox';
import Signup from './pages/signup/Signup';
import About from './pages/about/About';
import Help from './pages/help/Help';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import Page from './partials/Page';
import Pricing from './pages/pricing/Pricing';

function App() {
  return (
    <Page>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/about" element={<About />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/help" element={<Help />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/inbox" element={<Inbox />} />
      </Routes>
    </Page>
  );
}

export default App;

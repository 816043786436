import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import logo from "../assets/logoTransparent.png";
import { logout, userLoggedIn } from "../pages/login/loginSlice";
import "./Header.scss";

function Header() {
    const dispatch = useAppDispatch();
    const logoutUser = () => {
        dispatch(logout());
    }
    let userProfile = <></>;
    if (useAppSelector(userLoggedIn)) {
        userProfile = <button onClick={() => {logoutUser()}}>Logout</button>;
    }
    return(<header>
        <Link to="/">
            <picture className={"headerLogo"}>
                <img src={logo} alt="Oh No! Not Another Email Client" />
            </picture>
        </Link>
        {userProfile}
    </header>)
}

export default Header;
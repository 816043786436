import { useAppSelector } from "../app/hooks";
import "./FullScreenLoading.scss";
import { fsLoadingEvents } from "../appSlice";

function FullScreenLoading() {
    if (useAppSelector(fsLoadingEvents) > 0) {
        return(<div className={"fullScreenLoading"}>
            <div className={"loadingElement"}>
                <div className={"loadingCircle"}></div>
            </div>
        </div>)
    } else {
        return(<></>);
    }
}

export default FullScreenLoading;
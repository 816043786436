import PriceCard from "./PriceCard";
import "./Pricing.scss";

function Pricing() {
    return(<div className={"content"}>
        <h1>Pricing</h1>
        <div className={"priceCards"}>
            <PriceCard title={"Simple"} price={5}>
                <p>Perfect for teams just starting out</p>
                <p>10GB of shared space</p>
                <p>Scale as you need</p>
            </PriceCard>
            <PriceCard title={"Bigger"} price={25}>
                <p>Larger Teams</p>
                <p>Teams sending design files</p>
                <p>50GB of shared space</p>
                <p>Scale as you need</p>
            </PriceCard>
            <PriceCard title={"Advanced"} buyLabel={"Contact Us Today"}>
                <p>You want something not listed</p>
                <p>Teams who want something better</p>
                <p>Scale as you need</p>
            </PriceCard>
        </div>
    </div>)
}

export default Pricing;
import { Link } from "react-router-dom";

function About() {
    return(<div className={"content"}>
        <h1>About</h1>
        <p>We're building a small business centric email client - there are great options for large business which small businesses can use at a cost, or there are cheap alternatives that aren't business friendly and have the worst spam - and there are many of both options - so yes, it's a shame to have to build yet another system. But without a serious alternative this is what we must do... Oh No! Another Email Client!</p>
        <h2>Small Business Tools</h2>
        <h3>Pay for what you need</h3>
        <p>Let's get personal. When I started this project I had a client who paid for 25GB of Inbox space for each of their 12 employees. 11 of their employees had about 200MB in their inbox (0.2GB). The final employee (the graphic designer I might add) had about 6GB of data in their inbox. The graphic designer needed this space but the rest didn't. Sound familiar?</p>
        <p>We charge <strong>your company</strong> for the space you need. We start with 10GB. That's not 10GB per person - that's 10GB. Because let's be honest, a 10GB disk is 10GB whether 1 person uses it or 50 people do. We charge on company space - not the number of users you have. So you can have as many users as you need - goodbye sharing accounts!</p>
        <h3>Small Businesses need to give support</h3>
        <p>Speaking of sharing passwords - a terrible idea by the way. It's the age old support problem. Your company no doubt has a support@companydomain.com email address - or similar. In fact you probably have a number of "group" inboxes. So how do we tackle that? Traditionally we had two options.</p>
        <p><strong>The Shared Account Options</strong> - Everyone who has access to this inbox shares the password. You hope no one changes it to make life difficult, and should anyone send anything innappropriate there is no way of identifying the person. Did I mention you all have to share a password?! (Hint: in the IT world we call password sharing a <strong>REALLY BAD IDEA</strong>)</p>
        <p><strong>The Group Option</strong> - Create an email group - when a customer emails you, every person in the group gets a copy in their inbox. They then reply - as themselves from an address the customer hasn't know. So then does their colleague. And another. After having a discussion about not <em>all</em> responding to customers, the next customer emails and <strong>NO ONE</strong> responds...</p>
        <p>The solution? Dedicated group emails with transparency. When a user is added to a group, they will see the email - they will be able to respond to it - and decide to respond as the company - i.e. from the support@ email address, or as themselves <em>(Hi, I'm Matt and I'll be looking after your query). </em>This is then flagged to everyone else in the group - when they go in, if the response was from the group - they will see "Username responded on the company behalf" and a copy of the email. If the response was personal you might not want this - so the message won't be visible, but everyone will still see "User responded". No more confusion, no more 50 emails back to a customer, no more customers getting no response</p>
        <h3>Defenders of Spam</h3>
        <p>Sigh... Yep, we got into the email business so now let's talk about spam. In fact let's talk about spam in {new Date().getFullYear()}. It costs businesses all over the world a fortune. Let's break down the support</p>
        <h4>Automated Spam check</h4>
        <p>We continue to grow our spam learning database to identify spam. We have a cheeky list of words to look out for (the list looks really naughty), we check the language of the message, we look at IP - all the usual tricks. Then we do the next bits</p>
        <h4>Context aware</h4>
        <p>We check who works in your business. Has someone with the same name as a colleague emailed from an external address? Flag it.</p>
        <h4>Still unsure?</h4>
        <p>We work with small business. So if you <em>DO</em> fall victim to phising or otherwise - it's more work for us. Using our web app or dedicated mobile apps - click "Check Mail". (Fair use limit applies). One of our human operators will personally review the message and determine whether it's spam on not. <strong>Yes seriously. We hate spam enough that we will manually check a number of emails for you.</strong></p>
        <h4>Still want more?</h4>
        <p>We have to apply a fair use policy to the number of emails we check per month - but what if you want to be really careful? Speak to your sales rep about permanent human checking. For an additional fee our human operators will look after every piece of external mail coming into your domain. Before you even see it. That way all of your email is sorted for you - spam free! (Additional fees apply)</p>
        <h3>Sound Amazing? - Join Today!</h3>
        <p>Fill out an application form to join Today</p>
        <Link to="/signup">
            <button className="genericBtn">Join Today</button>
        </Link>
        <p></p>
    </div>)
}

export default About;
import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import appReducer from '../appSlice';
import loginReducer from '../pages/login/loginSlice';
import emailReducer from '../pages/inbox/emailSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['app']
}

const persistedReducer = persistReducer(persistConfig, combineReducers({
  app: appReducer,
  login: loginReducer,
  emails: emailReducer
}));

const rootReducer = (state: any, action: any) => {
  if (action.type === "login/logout") {
    // Delete all stored data
    storage.removeItem("persist:root");
    return persistedReducer(undefined, action);
  }
  return (persistedReducer(state, action));
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { userLoggedIn } from "../login/loginSlice";
import ComposeMailItem from "./components/ComposeMailItem";
import FolderContents from "./components/FolderContents";
import FolderView from "./components/FolderView";
import PreviewPane from "./components/PreviewPane";
import { emailsBeingWritten } from "./emailSlice";
import "./Inbox.scss";

function Inbox() {
    let newEmails = useAppSelector(emailsBeingWritten)?.map((email) => {
        return <ComposeMailItem email={email} key={email.guid} />
    });
    if (!useAppSelector(userLoggedIn)) {
        return <Navigate to="/login" />
    }
    return(<div id="inboxContent" className={"inboxPage"}>
        <div className={"emailsBeingComposed"}>{newEmails}</div>
        <FolderView />
        <FolderContents />
        <PreviewPane />
    </div>)
}

export default Inbox;
import { Link } from "react-router-dom";

function PriceCard(props: any) {
    let price = (<></>);
    if (props.price) {
        price = (<div className={"priceTag"}>From £ {props.price} per month<br />Unlimited Users</div>)
    }
    return(
        <div className={"priceCard"}>
            <h3>{props.title}</h3>
            <div>{props.children}</div>
            {price}
            <Link to="/signup"><button>{props.buyLabel || "Get Started!"}</button></Link>
        </div>
    )
}

export default PriceCard;
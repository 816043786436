import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faBurger, faChevronDown, faInbox, faPencil, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch } from "../../../app/hooks";
import { composeEmail } from "../emailSlice";
import { v4 as uuid } from "uuid";

function FolderView() {
    let dispatch = useAppDispatch();
    return(<div className={"folderView"}>
        <div className={"composeBtn"} onClick={() => dispatch(composeEmail(uuid()))}><FontAwesomeIcon icon={ faPlusCircle } /> New Email</div>
        <div className={"folderLabel active"}><FontAwesomeIcon icon={ faInbox } /> Inbox</div>
        <div className={"folderLabel"}><FontAwesomeIcon icon={ faPencil } /> Drafts</div>
        <div className={"folderLabel"}><FontAwesomeIcon icon={ faPaperPlane } /> Sent</div>
        <div className={"folderLabel"}><FontAwesomeIcon icon={ faBurger } /> Junk</div>
        <div className={"folderLabel"}><FontAwesomeIcon icon={ faChevronDown } /> More</div>
    </div>);
}

export default FolderView;
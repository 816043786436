import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import "./Index.scss";

function Index() {
    return(<div className={"content"}>
        <h1>Oh No! Not Another Email Client</h1>
        <h2><small>Small</small> Business Centric Email</h2>
        <p>After discovering that there wasn't a happy medium between cheap and nasty email, and full enterprise grade email - we did the only thing we could think of... We made yet another email service.</p>
        <p>We jam packed it with Business Centric tools to help you turn your startup into the next big thing! We'd love to join the ride with you!</p>
        <div className={"btnStack"}>
            <Link to="/signup">
                <button className={"genericBtn"}>Sign Up Now <FontAwesomeIcon icon={faArrowAltCircleRight} /></button>
            </Link>
            <Link to="/login">
                <button className={"genericBtn"}>Already kicking goals? Login Here</button>
            </Link>
        </div>
    </div>);
}
export default Index;
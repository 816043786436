import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface LoginState {
    user: string | null;
    token: string | null;
}

const initialState: LoginState = {
    user: null,
    token: null
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        login: (state, payload) => {
            state.user = "Test User";
            state.token = payload.payload;
        },
        logout: (state) => {
            state.user = null;
            state.token = null;
        }
    }
});

export const { login, logout } = loginSlice.actions;

export const userLoggedIn = (state: RootState) => state.login.token !== null;

export const currentToken = (state: RootState) => state.login.token;

export default loginSlice.reducer;
import { useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { activeFolder, activeEmail } from "../../inbox/emailSlice";
import ComponentLoading from "../../../components/ComponentLoading";
import { Config } from "../../../config";
import { currentToken } from "../../login/loginSlice";

interface loadingState {
    email: string | null;
    complete: boolean;
    loading: boolean;
}

function PreviewPane() {
    let [loadStatus, setLoadStatus] = useState({
        email: null,
        complete: false,
        loading: false
    } as loadingState);
    let [currentLoadingEvents, setCurrentLoadingEvents] = useState(0);
    let appMessage = useAppSelector(activeEmail);
    let appFolder = useAppSelector(activeFolder);
    let userToken = useAppSelector(currentToken);
    let [emailTitle, setEmailTitle] = useState("Select an Email to View");
    let [fromComponent, setFromComponent] = useState(<></>);
    let [emailComponent, setEmailComponent] = useState(<></>);
    if (appMessage !== loadStatus.email) {
        // Load the email
        setCurrentLoadingEvents(1);
        setLoadStatus({
            email: appMessage!,
            complete: false,
            loading: true
        });
        fetch(Config.apiEndpoint + "/api/email/" + appFolder! + "/" + appMessage!, {
            method: "GET",
            mode: 'cors',
            headers: {
                "Authorization": "Bearer " + userToken,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }).then((data) => {
            return data.json();
        }).then((data) => {
            setEmailTitle(data.email.subject || "(No Subject)");
            setFromComponent(<div className={"addressComponent"}>
                <strong>From: {data.email.from.text}</strong>
            </div>);
            setEmailComponent(<div dangerouslySetInnerHTML={{__html: data.email.body.html}}></div> || <div className={"textEmail"}>{data.email.body.text}</div>);
            setCurrentLoadingEvents(0);
            setLoadStatus({
                email: appMessage!,
                complete: true,
                loading: false
            });
        });
    }
    return(
    <div className={"previewPane"}>
        <ComponentLoading isActive={currentLoadingEvents > 0} />
        <h3>{emailTitle}</h3>
        {fromComponent}
        {emailComponent}
    </div>)
}

export default PreviewPane;
import { faFacebookF, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import logo from "../assets/logoWhite.png";
import "./Footer.scss";
function Footer() {
    return(<footer>
        <div className={"footerColumns"}>
            <div>
                <Link to="/">
                    <picture className="footerLogo">
                        <img src={logo} alt={"Oh No! Not Another Email Client"} />
                    </picture>
                </Link>
            </div>
            <ul>
            <li><strong>About:</strong></li>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/pricing">Pricing</Link></li>
            </ul>
            <ul>
                <li><strong>Help:</strong></li>
                <li><Link to="/help">Get Support</Link></li>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            </ul>
            <ul>
                <li><strong>Social:</strong></li>
                <li>
                    <a href="https://www.facebook.com/onnaec" target={"_blank"} rel={"noreferrer"} className={"socialIcon"}>
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                    <a href="https://www.linkedin.com/company/prudent-pixel-ltd" target={"_blank"} rel={"noreferrer"} className={"socialIcon"}>
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                </li>
            </ul>
        </div>
        <div className={"baseline"}>
            {/* Link to our own website - we know it isn't malicious, take referrals */}
            {/* eslint-disable-next-line */}
            <span>A <a href={"https://prudentpixel.com"} target={"_blank"} rel={"noopener"}>Prudent Pixel</a> Product - &copy; {new Date().getFullYear()}</span>
        </div>
    </footer>)
}

export default Footer;
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { currentFolder } from "../../../appSlice";
import ComponentLoading from "../../../components/ComponentLoading";
import { Config } from "../../../config";
import { currentToken } from "../../login/loginSlice";
import { loadEmail } from "../emailSlice";
import EmailMessageItem from "./EmailMessageItem";

export interface EmailMessageType {
    key: string;
    from: string;
    subject: string;
    date: string;
}

function populateMessages(messages: EmailMessageType[], dispatchObject: any) {
    return <>{
        messages.map((message) => {
            return <EmailMessageItem message={message} key={message.key} onClick={() => {dispatchObject(loadEmail(message.key))}} />
        })
    }</>;
}

function FolderContents() {
    let dispatch = useAppDispatch();
    let [contents, setContents] = useState(<span>Loading...</span>);
    let [currentLoadingEvents, setCurrentLoadingEvents] = useState(0);
    let [viewLoaded, setViewLoaded] = useState("NO"); // Use strings as there needs to be an in progress as well
    let targetFolder = useAppSelector(currentFolder);
    let userToken = useAppSelector(currentToken);
    useEffect(() => {
        async function loadFolderData() {
            setCurrentLoadingEvents(currentLoadingEvents + 1);
            setViewLoaded("IN PROGRESS");
            fetch(Config.apiEndpoint + "/api/emails/" + targetFolder, {
                method: "GET",
                mode: 'cors',
                headers: {
                    "Authorization": "Bearer " + userToken,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then((response) => {
                return response.json();
            })
            .then((folderData) => {
                setCurrentLoadingEvents(currentLoadingEvents - 1);
                setViewLoaded("YES");
                if (folderData.messages.length > 0) {
                    setContents(populateMessages(folderData.messages, dispatch));
                } else {
                    setContents(<span>Empty...</span>)
                }
            });
        };
        if (viewLoaded === "NO") {
            loadFolderData();
        }
    });

    return(<div className={"folderContents"}>
        <ComponentLoading isActive={currentLoadingEvents > 0} />
        <h2>{useAppSelector(currentFolder)?.toUpperCase()}</h2>
        {contents}
    </div>);
}

export default FolderContents;
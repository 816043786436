import { useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { decreaseFullScreenLoading, increaseFullScreenLoading } from "../../appSlice";
import { Config } from "../../config";
import "./Signup.scss";

function Signup() {
    let dispatch = useAppDispatch();
    let [name, setName] = useState('');
    let [currentEmail, setCurrentemail] = useState('');
    let [currentPhone, setCurrentPhone] = useState('');
    let [businessName, setBusinessName] = useState('');
    let [businessType, setBusinessType] = useState('');
    let [businessEmployees, setBusinessEmployees] = useState('');
    let [primaryDomain, setPrimaryDomain] = useState('');
    let [nameValidation, setNameValidation] = useState(<></>);
    let [currentEmailValidation, setCurrentEmailValidation] = useState(<></>);
    let [currentPhoneValidation, setCurrentPhoneValidation] = useState(<></>);
    let [businessNameValidation, setBusinessNameValidation] = useState(<></>);
    let [signupComplete, setSignupComplete] = useState(false);
    const doSignup = async () => {
        let passesValidation = true;
        if (name.length < 3) {
            passesValidation = false;
            setNameValidation(<span className={"validation"}>Please enter your name</span>);
        } else {
            setNameValidation(<></>);
        }
        if (currentEmail.length < 1 && currentPhone.length < 1) {
            passesValidation = false;
            setCurrentEmailValidation(<span className="validation">Please enter either a valid email address or valid phone number so we can get in contact</span>);
            setCurrentPhoneValidation(<span className="validation">Please enter either a valid email address or valid phone number so we can get in contact</span>);
        } else {
            if (currentEmail.length && !currentEmail.match(/.+@.+\..+/)) {
                passesValidation = false;
                setCurrentEmailValidation(<span className="validation">Please enter a valid email address</span>);
            } else {
                setCurrentEmailValidation(<></>);
            }
            if (currentPhone.length && (currentPhone.length < 7 || !currentPhone.match(/^[\s0-9()\-+]+$/))) {
                passesValidation = false;
                setCurrentPhoneValidation(<span className="validation">Please enter a valid phone number including country code. Phone numbers must only contain numbers, spaces, hyphens, and brackets at most.</span>)
            } else {
                setCurrentPhoneValidation(<></>);
            }
        }
        if (businessName.length < 3) {
            passesValidation = false;
            setBusinessNameValidation(<span className="validation">Please enter your business name.</span>);
        } else {
            setBusinessNameValidation(<></>);
        }
        if (passesValidation) {
            dispatch(increaseFullScreenLoading());
            await fetch(Config.apiEndpoint + "/expressInterest", {
                method: "POST",
                mode: "cors",
                body: JSON.stringify({
                    name,
                    email: currentEmail,
                    phone: currentPhone,
                    business_name: businessName,
                    business_type: businessType,
                    business_employees: businessEmployees,
                    business_domain: primaryDomain
                })
            }).then((response) => {
                return response.json();
            }).then((response) => {
                setSignupComplete(true);
                dispatch(decreaseFullScreenLoading());
            });
        }
    }
    if (signupComplete) {
        return (<h1>Thanks! Someone will be in touch shortly!</h1>);
    } else {
        return(<div id={"signupContent"} className={"content"}>
            <h1>Let's get Started!</h1>
            <p>Please note: This service is for businesses only. At this point in time we cannot offer this for individuals.</p>
            <h2>Tell Us A Bit About You!</h2>
            <label>
                Your Name:
                <input type="text" placeholder={"Your Name"} value={name} onChange={(e) => setName(e.target.value)} onKeyDown={(k) => {if(k.key === "Enter"){doSignup();}}} />
                {nameValidation}
            </label>
            <label>
                Your (current) email:
                <input type="email" placeholder={"An email we can get in touch with you on"} value={currentEmail} onChange={(e) => setCurrentemail(e.target.value)} onKeyDown={(k) => {if(k.key === "Enter"){doSignup();}}} />
                {currentEmailValidation}
            </label>
            <label>
                Your phone number:
                <input type="text" placeholder={"A phone number we can get in touch with you on"} value={currentPhone} onChange={(e) => setCurrentPhone(e.target.value)} onKeyDown={(k) => {if(k.key === "Enter"){doSignup();}}} />
                {currentPhoneValidation}    
            </label>
            <label>
                Your business Name:
                <input type="text" placeholder={"Business name"} value={businessName} onChange={(e) => setBusinessName(e.target.value)} onKeyDown={(k) => {if(k.key === "Enter"){doSignup();}}} />
                {businessNameValidation}
            </label>
            <label>Business Type:
                <select value={businessType} onChange={(e) => setBusinessType(e.target.value)} onKeyDown={(k) => {if(k.key === "Enter"){doSignup();}}}>
                    <option value="Sole Trader">Sole Trader</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Limited Company (Ltd., Pty. Ltd., LLC)">Limited Company (Ltd., Pty. Ltd., LLC etc.)</option>
                    <option value="Other">Other...</option>
                </select>
            </label>
            <label>Current Employees
                <select value={businessEmployees} onChange={(e) => setBusinessEmployees(e.target.value)} onKeyDown={(k) => {if(k.key === "Enter"){doSignup();}}}>
                    <option value="1">Just Me! (1)</option>
                    <option value="5">A few employees (2 - 5 employees)</option>
                    <option value="10">A small bunch (6 - 10 employees)</option>
                    <option value="25">Enough of us! (11 - 25 employees)</option>
                    <option value="100">A full start up worth (26 - 100 employees)</option>
                    <option value="999+">A huge operation (Greater than 101 employees)</option>
                </select>
            </label>
            <label>
                Primary domain:
                <input type="text" placeholder={"myawesomestartup.com"} value={primaryDomain} onChange={(e) => setPrimaryDomain(e.target.value)} onKeyDown={(k) => {if(k.key === "Enter"){doSignup();}}} />
                <small>No problem if you have more than one domain - we just need the primary one at this stage i.e. the one you would put on business cards etc.
                    <br />
                    Don't have a domain just yet? No worries! You <strong>will need one to setup email</strong> - but we can help you with this step - just leave this field blank 😊
                </small>
            </label>
            <button className={"genericBtn"} onClick={doSignup}>Signup!</button>
        </div>)
    }
}

export default Signup;